import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "@app/components/button";
import { FadeIn } from "@app/components/fade-in";
import { paths } from "@app/constants/paths";
import { useStoreUnverifiedEmail } from "@app/helpers/auth";

import { ApiErrors } from "@app/components/api-errors";
import { Dialog } from "@app/components/dialog";
import { Title } from "@app/components/title";
import { WarningNote } from "@app/components/warning-note";
import { isPWA } from "@app/config/env";
import { useMediaQuery } from "@app/hooks/use-media-query";
import { ButtonGroup } from "../button-group";
import { isValidEmail } from "../is-valid-email";
import { PasswordToggleButton } from "../password-toggle-button";
import { TextField } from "../text-field";
import { EmailIcon } from "./email-icon";
import styles from "./index.module.css";
import { LockIcon } from "./lock-icon";
import { useLogin } from "./use-login";

const loginErrors = {
	unverifiedAccount: "Please confirm your email with a link that we sent you.",
};

const LoginSelection = ({
	onForexClick,
	onArbClick,
}: {
	onForexClick: () => void;
	onArbClick: () => void;
}) => {
	return (
		<>
			<p className={styles.selectDescription}>
				Please select which account you'd like to log into:
			</p>
			<ButtonGroup
				data={[
					{
						title: "Forex",
						icon: (
							<svg
								role="presentation"
								xmlns="http://www.w3.org/2000/svg"
								width="32"
								height="32"
								viewBox="0 0 32 32"
								fill="none"
							>
								<path
									d="M4.10066 9.97644L9.81805 14.0602C10.1156 14.2727 10.2643 14.379 10.42 14.4035C10.557 14.425 10.6973 14.4033 10.8213 14.3413C10.9623 14.271 11.072 14.1247 11.2914 13.8322L12.4995 12.2214C12.5615 12.1387 12.5925 12.0973 12.629 12.0616C12.6614 12.03 12.6969 12.0017 12.735 11.9772C12.7779 11.9496 12.8251 11.9286 12.9196 11.8866L18.0777 9.59413C18.2916 9.4991 18.3985 9.45159 18.4791 9.37738C18.5504 9.31175 18.6065 9.23135 18.6436 9.1418C18.6855 9.04056 18.6933 8.92382 18.7088 8.69036L19.0865 3.02522M17.9993 17.9993L21.4874 19.4942C21.8921 19.6677 22.0944 19.7544 22.2016 19.8984C22.2956 20.0248 22.3419 20.1804 22.3321 20.3376C22.321 20.5168 22.1989 20.7 21.9547 21.0663L20.316 23.5244C20.2002 23.698 20.1423 23.7849 20.0659 23.8477C19.9982 23.9034 19.9201 23.9451 19.8363 23.9706C19.7416 23.9994 19.6372 23.9994 19.4285 23.9994H16.7682C16.4917 23.9994 16.3535 23.9994 16.2359 23.9531C16.132 23.9123 16.04 23.846 15.9684 23.7604C15.8874 23.6634 15.8437 23.5323 15.7562 23.27L14.8057 20.4185C14.7542 20.2638 14.7284 20.1865 14.7216 20.1079C14.7156 20.0382 14.7206 19.9679 14.7364 19.8998C14.7542 19.8229 14.7907 19.75 14.8636 19.6042L15.5856 18.1601C15.7323 17.8667 15.8057 17.72 15.9187 17.629C16.0182 17.5487 16.1388 17.4988 16.2659 17.4851C16.4102 17.4696 16.5658 17.5215 16.877 17.6252L17.9993 17.9993ZM29.3327 15.9993C29.3327 23.3631 23.3631 29.3327 15.9993 29.3327C8.63555 29.3327 2.66602 23.3631 2.66602 15.9993C2.66602 8.63555 8.63555 2.66602 15.9993 2.66602C23.3631 2.66602 29.3327 8.63555 29.3327 15.9993Z"
									stroke="#56A7A2"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						),
						onClick: onForexClick,
					},
					{
						onClick: onArbClick,
						icon: (
							<svg
								role="presentation"
								xmlns="http://www.w3.org/2000/svg"
								width="32"
								height="32"
								viewBox="0 0 32 32"
								fill="none"
							>
								<path
									d="M7.99935 7.99935L10.666 5.33268M10.666 5.33268L7.99935 2.66602M10.666 5.33268H7.99935C5.05383 5.33268 2.66602 7.7205 2.66602 10.666M23.9993 23.9993L21.3327 26.666M21.3327 26.666L23.9993 29.3327M21.3327 26.666H23.9993C26.9449 26.666 29.3327 24.2782 29.3327 21.3327M13.5847 8.66602C14.4728 5.21556 17.605 2.66602 21.3327 2.66602C25.751 2.66602 29.3327 6.24774 29.3327 10.666C29.3327 14.3937 26.7832 17.5258 23.3328 18.414M18.666 21.3327C18.666 25.751 15.0843 29.3327 10.666 29.3327C6.24774 29.3327 2.66602 25.751 2.66602 21.3327C2.66602 16.9144 6.24774 13.3327 10.666 13.3327C15.0843 13.3327 18.666 16.9144 18.666 21.3327Z"
									stroke="#56A7A2"
									strokeWidth="1.5"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						),
						title: "Crypto Arbitrage",
					},
				]}
			/>
		</>
	);
};

const Login = () => {
	const [showLoginSelection, setShowLoginSelection] = useState(false);
	const { onForexLogin, onArbLogin, onGoToArb } = useLogin();
	const [searchParams] = useSearchParams();
	const [showVerfiedDialog, setShowVerifiedDialog] = useState(
		searchParams.get("verified") === "true",
	);
	const username =
		(searchParams.get("email") ?? isPWA)
			? (window.localStorage.getItem("recent-username") ?? "")
			: "";
	const isExpired = searchParams.get("expired") === "true";
	const [isLoading, setIsLoading] = useState(false);

	const [showPassword, setShowPassword] = useState(false);
	const [storeUnverifiedEmail] = useStoreUnverifiedEmail();
	const isMobile = useMediaQuery();
	const navigate = useNavigate();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<{
		username: string;
		password: string;
	}>({
		defaultValues: {
			username,
			password: "",
		},
		mode: "onBlur",
	});

	const location = useLocation();
	const [apiErrors, setApiErrors] = useState<Array<string>>(
		location.state?.errorMessage ? [location.state.errorMessage] : [],
	);

	const handleForgotPassword = () => navigate(paths().forgotPassword);

	const handleNavigateToPlatform = () => {
		const redirect = window.sessionStorage.getItem("redirect");
		window.sessionStorage.removeItem("redirect");
		const ignored = [
			paths().root,
			paths().login,
			paths().register,
			paths().resetPassword,
			"/password-reset",
			paths().notVerified,
			paths().selectService,
			paths().selectForgotPassword,
			paths().error.generalError,
		];

		window.location.href =
			redirect && !ignored.includes(redirect) ? redirect : paths().root;
	};

	const handleLogin = async (values: {
		username: string;
		password: string;
	}) => {
		window.localStorage.removeItem("register-email");
		setIsLoading(true);
		const forexErrors = await onForexLogin(values);
		const arbErrors = await onArbLogin(values);

		if (isPWA) {
			window.localStorage.setItem("recent-username", values.username);
		}

		const hasNoErrors = forexErrors.length === 0 && arbErrors.length === 0;
		if (hasNoErrors) {
			setIsLoading(false);
			setShowLoginSelection(true);
			return;
		}

		if (
			arbErrors.length === 1 &&
			arbErrors[0] === "User inactive or deleted."
		) {
			onGoToArb(true);
			return;
		}

		const onlyArbSuccess = forexErrors.length > 0 && arbErrors.length === 0;
		if (onlyArbSuccess) {
			onGoToArb();
			return;
		}

		if (forexErrors.length > 0) {
			if (forexErrors.indexOf(loginErrors.unverifiedAccount) > -1) {
				storeUnverifiedEmail(values.username || null);
				navigate(paths().notVerified);
			} else {
				setApiErrors(forexErrors);
			}
			setIsLoading(false);
			return;
		}

		setIsLoading(false);
		handleNavigateToPlatform();
	};

	return (
		<>
			<form
				onSubmit={handleSubmit((data) => {
					handleLogin(data);
				})}
				noValidate
			>
				<>
					<Title>Welcome back</Title>
					{showLoginSelection ? (
						<LoginSelection
							onForexClick={handleNavigateToPlatform}
							onArbClick={onGoToArb}
						/>
					) : (
						<>
							{isExpired && (
								<WarningNote
									className={styles.warningNote}
									title="You were logged out due to inactivity."
								/>
							)}
							<div className={styles.formContent}>
								<TextField
									register={register}
									name="username"
									label="Email"
									type="email"
									validate={isValidEmail}
									required
									leftSection={<EmailIcon />}
									error={errors.username}
								/>
								<TextField
									register={register}
									name="password"
									label="Password"
									type={showPassword ? "text" : "password"}
									required
									leftSection={<LockIcon />}
									rightSection={
										<PasswordToggleButton
											value={showPassword}
											onChange={setShowPassword}
										/>
									}
									error={errors.password}
								/>
								<FadeIn show={apiErrors.length > 0}>
									<ApiErrors errors={apiErrors} />
								</FadeIn>
							</div>
							<div className={styles.actions}>
								<Button disabled={isLoading} type="submit" block>
									{isLoading ? "Logging in" : "Log in"}
								</Button>
								<Button variant="tertiary" onClick={handleForgotPassword} block>
									Forgot password
								</Button>
							</div>
						</>
					)}
				</>
			</form>

			<Dialog
				isOpen={showVerfiedDialog}
				onClose={() => setShowVerifiedDialog(false)}
				title="Email verified"
				description={
					<>
						{isMobile
							? "Your email has been verified."
							: "Your email has been successfully verified."}
						<br />
						Please log in to complete your account setup.
					</>
				}
			>
				<Button centered onClick={() => setShowVerifiedDialog(false)}>
					Continue to login
				</Button>
			</Dialog>
		</>
	);
};

export default Login;
