import { ReactNode, memo } from "react";
import { twMerge } from "tailwind-merge";

import { MultiSelect } from "@app/components/controls/multi-selects";
import { Tag } from "@app/components/tag";
import { Typography } from "@app/components/typography";

import { CountryIcon } from "@app/components/country-icon";
import { MappedCurrency } from "@app/hooks/use-currencies";

export const MultiSelectCurrencyView = memo(
	(props: {
		appendToParent?: boolean;
		className?: string;
		error?: boolean;
		actionLabel?: string;
		fadeSelectedItems?: boolean;
		filterInputAutoFocus?: boolean;
		filterPlaceholder?: string;
		hideOutlineContent?: boolean;
		itemClassName?: string;
		labelClassName?: string;
		optionLabel?: string;
		headingText?: string;
		options?: MappedCurrency[];
		placeholder?: string;
		required?: boolean;
		selectedItemClassName?: string;
		selectedItemsScrollable?: boolean;
		value?: MappedCurrency[];
		selectedItemTemplate?: (option: MappedCurrency) => ReactNode;
		showOutLineOnContent?: boolean;
		onBlur?: () => void;
		onChange?: (value: MappedCurrency[]) => void;
		onFocus?: () => void;
		onHide?: () => void;
		onOverflow?: (overflow: boolean) => void;
		onRemoveSelectedItem?: (option: MappedCurrency) => void;
		onShow?: () => void;
		defaultOptions?: MappedCurrency[];
		onItemClick?: () => void;
	}) => {
		const itemStyle = ["flex flex-row gap-2", props.itemClassName];
		const mainStyle = ["w-full", props.className];

		return (
			<MultiSelect
				appendToParent={props.appendToParent}
				className={twMerge(mainStyle)}
				error={props.error}
				options={props.options ?? props.defaultOptions ?? []}
				value={props.value}
				optionLabel={props.optionLabel ?? "code"}
				display="chip"
				filter
				filterInputAutoFocus={props.filterInputAutoFocus}
				filterPlaceholder={props.filterPlaceholder ?? "Search"}
				labelClassName={props.labelClassName}
				headingText="Filter by currency"
				placeholder={props.placeholder ?? "Please select currencies"}
				required={props.required}
				itemTemplate={(option) => (
					<span className={twMerge(itemStyle)}>
						<CountryIcon
							currencyCode={option.currencyCode}
							width={24}
							height={24}
						/>
						<Typography theme="textMd">{option?.currencyCode}</Typography>
						<Typography theme="textMd">
							<span>{"-"}</span>
						</Typography>
						<Typography theme="textMd">{option?.verboseName}</Typography>
					</span>
				)}
				selectedItemTemplate={(option) =>
					option ? (
						<span className="inline-flex gap-2 align-middle items-center h-full">
							<Tag
								containerClassName="px-0 py-0 pr-2 min-w-max"
								tagStyle="filter"
								showRemoveButton
								removeButtonPlacement="right"
								onRemove={(event) => {
									event.stopPropagation();
									if (props.onRemoveSelectedItem) {
										props.onRemoveSelectedItem(option);
									}
								}}
							>
								<CountryIcon
									currencyCode={option.currencyCode}
									width={20}
									height={20}
								/>
								<Typography theme="textMd">{option.currencyCode}</Typography>
							</Tag>
						</span>
					) : undefined
				}
				selectedItemsScrollable={props.selectedItemsScrollable}
				virtualized
				onBlur={props.onBlur}
				onChange={props.onChange}
				onFocus={props.onFocus}
				onHide={props.onHide}
				onOverflow={props.onOverflow}
				onShow={props.onShow}
			/>
		);
	},
);
