import { isLocalhost, isTest, isTest2 } from "@app/config/env";

export const isPrimaryShareholdersEnabled = false;
export const isMobileBottomsheetMenusEnabled = false;
export const isPaymentDetailsAddRecipientEnabled =
	isLocalhost || isTest || isTest2;
export const isBalanceCardUpdatesEnabled = true;
export const isOverscrollEnabled = isTest || isLocalhost || isTest2;
export const isOTPAuthEnabled = isTest || isLocalhost || isTest2;
export const isMultipleFileUploadEnabled = isTest || isLocalhost || isTest2;
